import { useEffect, useState } from 'react';
import { FaMoon, FaSun } from "react-icons/fa";


export default function ThemeMode() {
    // استخدم Local Storage لتخزين الوضع الحالي (فاتح أو داكن)
    const [theme, setTheme] = useState(localStorage.getItem("currentmode") ?? "dark");

    useEffect(() => {
        if (theme === "light") {
            document.documentElement.classList.remove("dark");
            document.documentElement.classList.add("light");
            document.body.style.background = "#f1f2f2";
            document.body.style.transition = "0.5s";

        } else {
            document.documentElement.classList.remove("light");
            document.documentElement.classList.add("dark");
            document.body.style.background = "#18212a";
            document.body.style.transition = "0.5s";
        }
    }, [theme]);

    const toggleTheme = () => {
        const newTheme = theme === "dark" ? "light" : "dark";
        localStorage.setItem("currentmode", newTheme);
        setTheme(newTheme);
    };

    return (
        <div>
            <button
                className=" rounded-md text-white transition duration-300"
                onClick={toggleTheme}
                aria-label="Theme Mode"
            >
                {theme === "dark" ? <FaSun size={24}/> : <FaMoon size={24}/>}
            </button>
        </div>
    );
}
