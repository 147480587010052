import { useEffect, useState } from 'react';
import NavebarMedia from './NavebarMedia';


import '../../App.css'
import { Link } from 'react-router-dom';
import ThemeMode from '../Theme/ThemeMode';
export default function NavLinks() {

    const [scrolling, setScrolling] = useState(false);

    const ScrollWindow = () => {
        if (window.scrollY > 50) {
            setScrolling(true);
        } else {
            setScrolling(false);
        }
    }


    useEffect(() => {
        window.addEventListener("scroll", ScrollWindow);
        return () => {
            window.removeEventListener("scroll", ScrollWindow);
        }
    }, [])

    const links = [
        { name: "الرئيسية", url: '/' },
        { name: "من نحن", url: '/aboutme' },
        { name: "خدماتنا", url: '/service' },
        { name: "استضافة المواقع", url: '/hostingwebsite' },
        { name: "اتصل بنا", url: 'https://wa.me/+201503013422' },
    ]

    return (
        <>


            <nav class={`bg-[rgba(0,0,0,0.7)] h-20 mt-2 hidden lg:flex ${scrolling ? 'fixed top-0 left-0 w-full z-50 mt-[0px]' : 'sticky'}`} >
                <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <ThemeMode />

                    <div class="hidden w-full md:block md:w-auto  mx-auto  " id="navbar-default ">
                        <ul class=" font-medium flex justify-center items-center ">
                            {
                                links.map((item, index) => (
                                    <li key={index}>
                                        <Link to={item.url} class="block  text-white rounded md:bg-transparent px-10" aria-current="page">{item.name}</Link>
                                    </li>
                                ))
                            }

                            <a href='https://wa.me/+201503013422'>
                                <button aria-label="btn" class="cssanimation pepe bg-white px-3 py-2 rounded-md text-[#00a875] ">
                                    اسستشارة مجانية
                                </button>
                            </a>
                        </ul>
                    </div>
                </div>
            </nav>



            <div className=' w-full overflow-hidden lg:hidden'>

                <NavebarMedia />
            </div>

        </>
    );
}
