import NavSocial from './NavSocial'
import NavLinks from './NavLinks'

export default function Navebar() {
    return (
        <div 
            className='navebar'
            style={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',  // هنا خليت لون الخلفية شفاف جزئياً
                position: 'absolute',
                width: '100%',
                top: 0,
                left: 0,
                zIndex: 10,
            }}
        >
            <NavSocial />
            <NavLinks />
        </div>
    )
}