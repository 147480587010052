import { Outlet } from 'react-router-dom';

import Navebar from '../components/Navebar/Navebar';
import Footer from '../components/footer/Footer';


export default function Root() {
    return (
        <div>
            <Navebar />

            <div className='overflow-hidden z-50'>
                <Outlet />
            </div>
            <Footer />
        </div>
    )
}
