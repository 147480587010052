import logo from '../../Images/loader/logo preloader.png'

export default function Loading() {
  return (
    <div className='flex justify-center items-center'>
      <div className='my-[70%]  md:my-[400px] lg:my-[300px] 2xl:my-[400px]'>
        <img src={logo}  width={100} loading="lazy" className='mb-2'/>
        <div className="loader mx-auto"></div>
      </div>
    </div>
  )
}
