import { Link } from 'react-router-dom';

import IconNavebar from '../../Images/Navebar/Picture → vision.webp.svg';
import TelephoneIcon from '../../Images/Navebar/telephone-svgrepo-com.svg'
import Logo from '../../Images/Navebar/logo.svg';
import EmailIcon from '../../Images/Navebar/Vector (2).svg';


export default function NavSocial() {
    return (
        <div className=' h-[80px] pt-2 lg:px-[405px] px-16'>
            <div className='container flex justify-between items-center'>
                <Link to={"/"}>
                    <img src={Logo} className='w-[full] h-[full] mt-[-5px]' alt='Logo-Navebar' loading="lazy" />
                </Link>

                <div className='flex h-[60px]  '>

                    <div className='px-7 hidden justify-center items-center border-l-4 border-white md:flex'>
                        <div className='flex justify-center items-center text-white gap-6'>
                            <a href='https://wa.me/+201503013422' className='flex justify-center items-center'>
                                <p>201503013422</p>

                                <img src={TelephoneIcon} className=' py-7 mx-2' width={30} alt='TelephoneIcon' loading="lazy" />
                            </a>
                            <a href="mailto:wasllatech@wasllatech.com" className='flex justify-center items-center'>
                                <p>
                                    wasllatech@wasllatech.com
                                </p>
                                <img src={EmailIcon} className=' py-7 mx-2' height={30} alt='EmailIcon' loading="lazy" />

                            </a>
                        </div>

                    </div>
                    
                    <img src={IconNavebar} className='w-full mr-3  md:h-full' height={80} alt='IconNavebar' loading="lazy" />

                </div>

            </div>
        </div>
    )
}
