import { lazy, Suspense, useEffect, useState } from 'react';

import { Route, Routes } from 'react-router-dom';

import './App.css';


import Loading from './components/LoadingPage/Loading';

import Root from './route/Root';


const HeroSection = lazy(() => import('./route/Pages/Hero-section/HeroSection'));

const ServicesPage = lazy(() => import('./route/Pages/ServicesPage/ServicesPage'));

const AboutMe = lazy(() => import('./route/Pages/AboutMePage/AboutMe'));

const EcommerceWebsite = lazy(() => import('./route/Pages/OffersService/Ecommerce/EcommerceWebsite'));

const Websitedevelopment = lazy(() => import('./route/Pages/OffersService/website/Websitedevelopment'));

const HostingWebsite = lazy(() => import('./route/Pages/OffersService/Hosting/HostingWebsite'));

const SeoPage = lazy(() => import('./route/Pages/OffersService/Seo page/SeoPage'));

const BrandIdentity = lazy(() => import('./route/Pages/OffersService/Brand Identity/BrandIdentity'));

const MobileApp = lazy(() => import('./route/Pages/OffersService/MobileApp/MobileApp'));

const PrivacyPolicy = lazy(() => import('./route/Pages/privacy policy/PrivacyPolicy'));


function App() {
  // ture
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const timer = setTimeout(() => {

      setLoading(false);
      // loading = false
    }, 1500);


    return () => clearTimeout(timer);
  }, []);


  return (
    <>

      {
        loading ? (<Loading />) : (
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path='/' element={<Root />}>
                <Route index element={<HeroSection />} />
                <Route path='/aboutme' element={<AboutMe />} />
                <Route path='/service' element={<ServicesPage />} />
                <Route path='/mobileapp' element={<MobileApp />} />
                <Route path='/e-commercewebsite' element={<EcommerceWebsite />} />
                <Route path='/websitedevelopment' element={<Websitedevelopment />} />
                <Route path='/hostingwebsite' element={<HostingWebsite />} />
                <Route path='/seopage' element={<SeoPage />} />
                <Route path='/brandidentity' element={<BrandIdentity />} />
                <Route path='/privacypolicy' element={<PrivacyPolicy />} />
              </Route>
            </Routes>
          </Suspense>
        )
      }
    </>
  );
}

export default App;
