import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


import ExitIcon from "../../Images/Navebar/x.svg";
import TelephoneIcon from '../../Images/Navebar/telephone-svgrepo-com.svg';
import EnailIcon from '../../Images/Navebar/Vector (2).svg';
import ThemeMode from '../Theme/ThemeMode';

const links = [
    { name: "الرئيسية", url: '/' },
    { name: "من نحن", url: '/aboutme' },
    { name: "خدماتنا", url: '/service' },
    { name: "اتصل بنا", url: 'https://wa.me/+201503013422' },
    { name: "منتجاتنا" },
    { name: "اعمالنا" },
]
export default function NavebarMedia() {

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };



    const [scrolling, setScrolling] = useState(false);

    const ScrollWindow = () => {
        if (window.scrollY > 50) {
            setScrolling(true);
        } else {
            setScrolling(false);
        }
    }


    useEffect(() => {
        window.addEventListener("scroll", ScrollWindow);
        return () => {
            window.removeEventListener("scroll", ScrollWindow);
        }
    }, [])

    return (
        <>
            <nav class={`bg-[rgba(0,0,0,0.7)] h-20 mt-2 ${scrolling ? 'fixed top-0 left-0 w-full z-50 mt-[0px]' : 'sticky'}`}>
                <div class="max-w-screen-xl  flex  items-center justify-between mx-auto p-4">
                    <button
                        onClick={toggleMenu}
                        type="button"
                        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg lg:hidden hover:bg-white hover:text-black"
                        aria-controls="navbar-default"
                        aria-expanded={isOpen}
                    >
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                    <div
                        className={`fixed inset-y-0 left-0 transform  ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out w-64 bg-[#18212a]`}
                        id="navbar-default"
                    >
                        <button
                            onClick={toggleMenu}
                            type="button"
                            className="inline-flex items-center pt-6 mr-2 w-10 h-10 justify-center "
                            aria-controls="navbar-default"
                            aria-expanded={isOpen}
                        >

                            <img src={ExitIcon} alt='ExitIcon' />


                        </button>

                        <ul className="font-medium flex flex-col p-4 space-y-4">
                            {
                                links.map((item, index) => (
                                    <li key={index} >
                                        <Link to={item.url} className="block py-2 px-3 text-white" aria-current="page" onClick={toggleMenu}>{item.name}</Link>
                                    </li>

                                ))
                            }
                            <a href='https://wa.me/+201503013422'>
                                <button aria-label="btn" class="cssanimation pepe bg-white px-3 py-2 rounded-md text-[#00a875] ">
                                    اسستشارة مجانية
                                </button>
                            </a>
                        </ul>

                    </div>
                    <ThemeMode />
                    <div className=' w-[70%]'>
                        <a href="mailto:wasllatech@wasllatech.com">
                            <div className='flex  items-center text-white text-sm'>

                                <img src={EnailIcon} className='w-[24px] mx-3' width={24} loading="lazy" />
                                <p >wasllatech@wasllatech.com</p>
                            </div>
                        </a>

                        <a href='https://wa.me/+201503013422' >

                            <div className='flex items-center text-white text-sm pt-[10px]'>
                                <img src={TelephoneIcon} className='w-[24px] mx-3' width={24} loading="lazy" />
                                <p >01503013422</p>
                            </div>
                        </a>
                    </div>

                </div>
            </nav>

        </>
    )
}
