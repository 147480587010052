import LogoLight from '../../Images/AboutSection/logo-dark.png';


import WatsIcon from '../../Images/Social/Item.svg';
import InstaIcon from '../../Images/Social/Item (1).svg';
import FacebookIcon from '../../Images/Social/Item (2).svg';
import LinkedInIcon from '../../Images/Social/Item (3).svg';

import TelIcon from '../../Images/Navebar/telephone-svgrepo-com.svg';
import EmailIcon from '../../Images/Navebar/Vector (2).svg';
import { Link } from 'react-router-dom';


const services = [
    { link: "تصميم متجر الكتروني", url: "/e-commercewebsite" },
    { link: "تطوير و تصميم مواقع الانترنت", url: "/websitedevelopment" },
    { link: "استضافة مواقع وحجز دومينات", url: "/hostingwebsite" },
    { link: "أرشفة مواقع (SEO)", url: "/seopage" },
    { link: "تصميم تطبيقات الجوال", url: "/mobileapp" },
    { link: "تصميم الهوية التجارية", url: "/brandidentity" },
    // { link: "التسويق الإلكتروني", url: "" },
    // { link: "موشن جرافيك", url: "" },
]

const pages = [
    { name: "الرئيسية", url: '/' },
    { name: "من نحن", url: '/aboutme' },
    { name: "خدماتنا", url: '/service' },
    { name: "استضافة المواقع", url: '/hostingwebsite' },
    { name: "اتصل بنا", url: 'https://wa.me/+201503013422' },
    { name: "سياسة الخصوصية", url: '/privacypolicy' },
]



export default function Footer() {
    return (
        <footer>
            <div className="bg-[#18212a] h-full pb-10">
                <div className="grid grid-cols-1 lg:grid-cols-3 w-full ">
                    <div className="h-full mx-auto my-10 md:w-[80%]">
                        <div className='w-[80%]  lg:w-[100%] mx-auto '>

                            <div className='h-[150px]'>
                                <img src={LogoLight} alt="Logo" className=' mx-auto block' width={90} loading="lazy" />


                            </div>
                            <h1 className='text-[30px] text-[#f1f2f2] text-center font-medium'>وصله تك</h1>
                            <p className='w-full text-[#f1f2f2] text-center'>

                                شركة مصرية ,سعودية متخصصة ببرمجة وتصميم تطبيقات الجوال والمواقع لأنظمة الآيفون والأندرويد. نسعى نقدم لك حلول تقنية متكاملة تناسب احتياجاتك.
                            </p>

                            {/* <div className='my-4'>
                                <h1 className='text-[22px] text-[#f1f2f2] text-center my-2'>اتصل بنا</h1>
                                <div className='w-[50%] mx-auto flex flex-wrap  items-center gap-4 text-[#f1f2f2]'>
                                    <a href='https://wa.me/+201040967441' className='flex justify-center items-center gap-5'>
                                        <img src={TelIcon} className='h-7 w-7' alt='icon' />
                                        <p>201040967441</p>
                                    </a>
                                    <a href="mailto:wasllatech@wasllatech.com" className='flex justify-center items-center gap-5'>
                                        <img src={EmailIcon} className='h-7 w-7' alt='icon' />
                                        <p>wasllatech@wasllatech.com</p>
                                    </a>
                                </div>

                            </div> */}
                            <div className='my-4'>

                                <h1 className='text-[22px] text-[#f1f2f2] text-center my-2'>مواقع التواصل الخاصه بنا</h1>
                                <div className='flex justify-center items-center gap-5 mt-5'>
                                    <a href='https://wa.me/+201503013422'><img src={WatsIcon} className='w-10' alt='icon' width={10} loading='lazy' /></a>
                                    {/* <a href='#'><img src={InstaIcon} className='w-10' alt='icon' /></a> */}
                                    <a href='https://www.facebook.com/profile.php?id=61565759383514&mibextid=ZbWKwL'><img src={FacebookIcon} className='w-10' width={10} alt='icon' loading='lazy' /></a>
                                    {/* <a href='#'><img src={LinkedInIcon} className='w-10' alt='icon' /></a> */}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="h-full mx-auto my-10 md:w-[100%] text-center text-[#f1f2f2]">
                        <h1 className='text-[22px]'>خدماتنا</h1>
                        {
                            services.map((item, index) => (
                                <ul className='mt-[20px]' key={index}>
                                    <Link to={item.url}>{item.link}</Link>
                                </ul>
                            ))
                        }

                    </div>
                    <div className="h-full mx-auto my-10 md:w-[100%] text-center text-[#f1f2f2]">
                        <h1 className='text-[22px]'>صفحات تهمك</h1>
                        {
                            pages.map((item, index) => (
                                <ul className='mt-[20px]' key={index}>
                                    <Link to={item.url}>
                                        {item.name}
                                    </Link>
                                </ul>
                            ))
                        }

                    </div>
                </div>
                <div className='w-[90%] mx-auto text-center text-white text-sm lg:text-lg'>
                    <h1>جميع الحقوق محفوظة لدى &copy; شركة وصله تك  2024</h1>
                </div>
            </div>
        </footer>
    )
}
